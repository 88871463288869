<template>
    <div>
        <ts-page-title
            :title="$t('productPackaging.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('productPackaging.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper>
                <div class="tw-flex tw-justify-between tw-items-center">
                    <div class="tw-space-x-3">
                        <a-button
                            v-if="$can('create-product-packaging')"
                            type="primary"
                            @click="addNew"
                        >
                            {{ $t("addNew") }}
                        </a-button>
                        <a-range-picker
                            v-model="dataRange"
                            format="DD-MM-YYYY"
                            valueFormat="DD-MM-YYYY"
                            :allowClear="true"
                            class="tw-w-52"
                            @change="fetchData()"
                        />
                    </div>
                    <div class="tw-space-x-3">
                        <a-select
                            v-model="deliveryStatusId"
                            mode="multiple"
                            placeholder="All Status"
                            style="width: 200px"
                            :maxTagCount="1"
                            :options="deliveryStatus"
                            @change="fetchData()"
                        >
                        </a-select>
                        <a-tooltip :title="$t('productPackaging.search')">
                            <a-input-search
                                v-model="search"
                                :placeholder="$t('productPackaging.search')"
                                style="width: 200px"
                            />
                        </a-tooltip>
                    </div>
                </div>
            </ts-panel-wrapper>
            <div class="tw-overflow-x-scroll">
                <ts-table
                    :columns="columns"
                    :records="resources"
                    :loading="loading"
                    :expandable="true"
                >
                    <template v-slot="{ record }">
                        <td class="tw-whitespace-nowrap">
                            {{ record.packaging_date }}
                        </td>
                        <td>{{ record.packaging_number }}</td>
                        <td>{{ record.shop_name }}</td>
                        <td>{{ record.receiver_name }}</td>
                        <td>{{ record.receiver_phone }}</td>
                        <td>{{ record.receiver_location }}</td>
                        <td>{{ record.service_type_name_en }}</td>
                        <td class="text-center">
                            <TsAccountingFormat
                                :value="record.purchase_amount"
                            />
                        </td>
                        <td>
                            <span
                                class="badge bg-primary"
                                v-if="record.fee_paid_by == 2"
                                >Shop</span
                            >
                            <span class="badge bg-danger" v-else>Buyer</span>
                        </td>
                        <td>{{ record.remarks }}</td>
                        <td>
                            <span
                                class="badge bg-primary"
                                v-if="record.booking_count"
                                >Yes</span
                            >
                            <span class="badge bg-danger" v-else>No</span>
                        </td>
                        <td class="tw-text-center">
                            {{ countProductDetail(record.details) }}
                        </td>
                        <td class="tw-text-center tw-whitespace-nowrap">
                            {{ record.delivery_status }}
                        </td>
                        <td class="tw-text-center">
                            <span
                                class="badge tw-bg-green-500"
                                v-if="record.is_delivered"
                                >Out</span
                            >
                            <span
                                class="badge tw-bg-yellow-500"
                                v-else-if="!record.is_return"
                                >Pending</span
                            >
                            <span
                                class="badge tw-bg-blue-500"
                                v-else-if="record.is_return"
                                >Return</span
                            >
                        </td>
                        <td class="tw-text-center">
                            {{ record.user_name }}
                        </td>
                        <td class="tw-text-center">
                            {{ record.deleted_reason }}
                        </td>
                        <td
                            class="text-left tw-space-x-3 tw-whitespace-nowrap"
                            style="width:100px"
                        >
                            <template v-if="!record.is_deleted">
                                <a-tooltip
                                    :title="$t('productPackaging.confirm')"
                                    v-if="
                                        $whois.admin() &&
                                            record.delivery_status == null
                                    "
                                >
                                    <a
                                        href="#"
                                        @click.prevent="
                                            openModalConfirm(record)
                                        "
                                        ><i class="fas fa-truck"></i
                                    ></a>
                                </a-tooltip>
                                <a-popconfirm
                                    v-if="!record.is_jd_confirmed"
                                    placement="leftTop"
                                    :title="
                                        $t(
                                            'productPackaging.are_you_sure_to_confirm_stock'
                                        )
                                    "
                                    :ok-text="$t('yes')"
                                    :cancel-text="$t('no')"
                                    @confirm="onConfirmDeductStock(record)"
                                >
                                    <a href="#"
                                        ><i
                                            class="fas fa-truck-loading tw-text-yellow-600"
                                        ></i
                                    ></a>
                                </a-popconfirm>
                                <!-- v-if="!record.is_jd_confirmed" -->
                                <a
                                    href="#"
                                    v-tooltip="$t('edit')"
                                    class="text-primary mr-2"
                                    @click.prevent="edit(record)"
                                >
                                    <i class="fas fa-edit"></i>
                                </a>
                                <a-popconfirm
                                    v-if="
                                        $can('delete-product-packaging') &&
                                            !record.is_jd_confirmed
                                    "
                                    placement="leftTop"
                                    :title="$t('are_you_sure_to_delete')"
                                    :ok-text="$t('yes')"
                                    :cancel-text="$t('no')"
                                    @confirm="deleteRecord(record)"
                                >
                                    <a href="#" :disabled="record._deleting">
                                        <i
                                            class="fas fa-circle-notch spin"
                                            v-if="record._deleting"
                                        ></i>
                                        <i
                                            class="fa fa-trash text-red"
                                            v-else
                                        ></i>
                                    </a>
                                </a-popconfirm>
                            </template>
                        </td>
                    </template>
                    <template v-slot:expand="{ record }">
                        <table>
                            <thead>
                                <tr class="tw-border-b">
                                    <th class="tw-font-mono tw-px-4">No</th>
                                    <th class="tw-font-mono tw-px-4">
                                        Product Name
                                    </th>
                                    <th
                                        class="tw-font-mono tw-px-4 tw-text-center"
                                    >
                                        Quantity
                                    </th>
                                    <th class="tw-font-mono tw-px-4">UOM</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="(d, index) in record.details"
                                    :key="index"
                                    class="tw-border-b tw-border-dashed"
                                >
                                    <td class="tw-font-mono tw-px-4">
                                        {{ index + 1 }}
                                    </td>
                                    <td class="tw-font-mono tw-px-4">
                                        {{ d.product_name_en }}
                                    </td>
                                    <td
                                        class="tw-font-mono tw-px-4 tw-text-center"
                                    >
                                        {{ d.quantity }}
                                    </td>
                                    <td class="tw-font-mono tw-px-4">
                                        {{ d.uom_name_en }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </template>
                    <template v-slot:tfooter>
                        <td colspan="9" class="tw-text-right">
                            Total :
                        </td>
                        <td class="tw-text-right tw-px-2">
                            $
                            {{
                                $formatNumber(
                                    sumBy(resources, "purchase_amount")
                                )
                            }}
                        </td>
                        <td colspan="3"></td>
                        <td class="text-center">{{ countTotalQyt }}</td>
                        <td colspan="5"></td>
                    </template>
                </ts-table>
            </div>
            <div class="d-flex justify-content-end tw-p-4">
                <ts-pagination
                    v-model="pagination"
                    @navigate="fetchData"
                ></ts-pagination>
            </div>
        </ts-panel>

        <!-- form-action -->
        <a-modal
            v-if="showForm"
            v-model="showForm"
            :title="$t('productPackaging.pageTitle')"
            centered
            :maskClosable="false"
            @cancel="clearEdit"
            :zIndex="1020"
            :footer="null"
            width="50%"
        >
            <FormAction @cancel="clearEdit" />
        </a-modal>

        <a-modal
            v-if="isConfirmed"
            v-model="isConfirmed"
            :title="$t('productPackaging.confirm')"
            centered
            :maskClosable="false"
            @cancel="closeModalConfirm"
            :zIndex="1020"
            :footer="null"
        >
            <ModalConfirm
                :record="packagingRecord"
                @cancel="closeModalConfirm"
            />
        </a-modal>

        <a-modal
            v-if="convertToBooking"
            v-model="convertToBooking"
            :title="$t('bookingPickup.pageTitle')"
            centered
            :maskClosable="false"
            @cancel="closeFormConvertBooking"
            :zIndex="1020"
            :footer="null"
        >
            <CovertBooking
                :packaging="packagingData"
                @cancel="closeFormConvertBooking"
            />
        </a-modal>
    </div>
</template>
<script>
import { debounce, sumBy } from "lodash";
import { mapActions, mapState } from "vuex";
import { Errors } from "form-backend-validation";
import FormAction from "./form";
import Reason from "./reason-delete.vue";
import ModalConfirm from "./confirm-modal.vue";
import CovertBooking from "./convert-booking.vue";

export default {
    name: "productPackaging",
    components: {
        FormAction,
        CovertBooking,
        ModalConfirm
    },
    data() {
        return {
            loading: false,
            showForm: false,
            isConfirmed: false,
            convertToBooking: false,
            packagingData: [],
            packagingRecord: null,
            errors: new Errors(),
            model: {
                deleted_reason: ""
            }
        };
    },
    computed: {
        ...mapState("inventory/productPackaging", ["resources", "pagination"]),
        deliveryStatus: () => {
            return [
                { value: 2, label: "On delivery" },
                { value: 3, label: "Re-delivery" },
                { value: 4, label: "Dropped" },
                { value: 5, label: "Transferred" },
                { value: 6, label: "Success" },
                { value: 7, label: "Cancelled" },
                { value: 8, label: "Pending" },
                { value: 9, label: "Return" }
            ];
        },
        countProductDetail: () => rowDetail => {
            return sumBy(rowDetail, "quantity");
        },
        countTotalQyt() {
            let total = 0;
            this.resources.forEach(element => {
                total += sumBy(element.details, "quantity");
            });

            return total;
        },
        search: {
            get() {
                return this.$store.state.inventory.productPackaging.search;
            },
            set(newValue) {
                this.$store.commit(
                    "inventory/productPackaging/SET_SEARCH",
                    newValue
                );
                this.$store.commit(
                    "inventory/productPackaging/RESET_CURRENT_PAGE"
                );
            }
        },
        deliveryStatusId: {
            get() {
                return this.$store.state.inventory.productPackaging
                    .delivery_status_id;
            },
            set(newValue) {
                this.$store.commit(
                    "inventory/productPackaging/SET_DELIVERY_STATUS_ID",
                    newValue
                );
            }
        },
        isJdConfirmed: {
            get() {
                return this.$store.state.inventory.productPackaging
                    .isJdConfirmed;
            },
            set(newValue) {
                this.$store.commit(
                    "inventory/productPackaging/SET_CONFIRMED",
                    newValue
                );
            }
        },
        dataRange: {
            get() {
                return this.$store.state.inventory.productPackaging.date_range;
            },
            set(newValue) {
                this.$store.commit(
                    "inventory/productPackaging/SET_DATE_RANGE",
                    newValue
                );
            }
        },
        columns() {
            return [
                {
                    name: this.$t("productPackaging.packagingDate"),
                    sortKey: "packaging_date"
                },
                {
                    name: this.$t("productPackaging.packagingNumber"),
                    sortKey: "packaging_number"
                },
                {
                    name: this.$t("productPackaging.shopName"),
                    sortKey: "shop_name"
                },
                {
                    name: this.$t("productPackaging.receiverName"),
                    sortKey: "receiver_name"
                },
                {
                    name: this.$t("productPackaging.phoneNumber"),
                    sortKey: "receiver_phone"
                },
                {
                    name: this.$t("productPackaging.receiverLocation"),
                    sortKey: "receiver_location"
                },
                {
                    name: this.$t("productPackaging.serviceType"),
                    sortKey: "service_type_name_en"
                },
                {
                    name: this.$t("productPackaging.purchaseAmount"),
                    sortKey: "purchase_amount"
                },
                { name: this.$t("productPackaging.paidBy") },
                { name: this.$t("productPackaging.remark") },
                { name: this.$t("productPackaging.booking") },
                { name: this.$t("productPackaging.quantity") },
                { name: this.$t("productPackaging.status") },
                { name: this.$t("productPackaging.stock") },
                {
                    name: this.$t("productPackaging.createdBy"),
                    sortKey: "user_name"
                },
                { name: this.$t("productPackaging.deletedReason") },
                {
                    name: this.$t("actions")
                }
            ];
        }
    },
    methods: {
        sumBy,
        ...mapActions("inventory/productPackaging", [
            "destroy",
            "confirmDeductStock"
        ]),
        fetchData(attributes) {
            this.loading = true;
            this.$store
                .dispatch("inventory/productPackaging/fetch", { ...attributes })
                .then(() => {
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.$notify({ type: "error", text: error.message });
                });
        },
        addNew() {
            this.showForm = true;
        },
        clearEdit() {
            this.$store.commit("inventory/productPackaging/SET_EDIT_DATA", {});
            this.fetchData();
            this.showForm = false;
        },
        openModalConfirm(item) {
            this.isConfirmed = true;
            this.packagingRecord = item;
        },
        openConvertBooking(item) {
            this.convertToBooking = true;
            this.packagingData = item;
        },
        closeModalConfirm() {
            this.isConfirmed = false;
            this.fetchData();
        },
        closeFormConvertBooking() {
            this.convertToBooking = false;
            this.fetchData();
        },
        edit(record) {
            this.$store.commit(
                "inventory/productPackaging/SET_EDIT_DATA",
                record
            );
            this.showForm = true;
        },
        deleteRecord(record) {
            this.model.deleted_reason = null;
            this.$confirm({
                title: this.$t("are_you_sure_to_delete"),
                content: h =>
                    h(Reason, {
                        props: {
                            value: this.model,
                            validate: this.errors,
                            placeholder: this.$t("productPackaging.leaveReason")
                        }
                    }),
                okText: this.$t("yes"),
                okType: "danger",
                cancelText: this.$t("no"),
                onOk: () => {
                    return this.destroy({
                        id: record.packaging_id,
                        data: this.model
                    })
                        .then(response => {
                            this.$notify({
                                type: "success",
                                text: response.message
                            });
                            this.fetchData();
                            return Promise.resolve(response);
                        })
                        .catch(error => {
                            this.$notify({
                                type: "error",
                                text: error.message
                            });
                            this.errors = new Errors(error.errors);
                            return Promise.reject(error);
                        });
                },
                onCancel() {}
            });
        },
        onConfirmDeductStock(record) {
            this.confirmDeductStock(record.packaging_id)
                .then(response => {
                    this.$notify({
                        type: "success",
                        text: response.message
                    });
                    this.fetchData();
                })
                .catch(error => {
                    this.$notify({
                        type: "error",
                        text: error.message
                    });
                });
        }
    },
    watch: {
        search: debounce(function() {
            this.fetchData();
        }, 500)
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.fetchData();
        });
    },
    beforeRouteLeave(to, from, next) {
        this.$store.commit("inventory/productPackaging/RESET_STATE");
        next();
    }
};
</script>
